<!--
  系统管理-用户管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
	<el-card class="box-card">
	  <div slot="header" class="card-header">
      <h3 class="card-title titleCont" style="padding-left: 0px;">
        账号管理
      </h3>
	     <div style="float: left;">
	        <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
	      </div>
	      <div style="float:right;">
	         <el-button type="success"  icon="el-icon-circle-plus-outline"  @click="onAdd()">新增</el-button>
	      </div>
	  </div>
	  <div class="card-body p-0">
	    <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
	    					style="width: 100%; margin-top: 0px; ">
			  <el-table-column type="index" width="120px;" label="序号"></el-table-column>
				<el-table-column prop="userName" label="用户名">
				  <template slot-scope="scope">
					  <span>{{scope.row.userName}}</span>
				  </template>
				</el-table-column>
			  <el-table-column prop="name" label="姓名">
				  <template slot-scope="scope">
					  <span>{{scope.row.name}}</span>
				  </template>
			  </el-table-column>
				<el-table-column prop="type" label="类型">
				  <template slot-scope="scope">
						<span v-if="scope.row.type==10">总账号</span>
						<span v-else>子账号</span>
				  </template>
				</el-table-column>
				<el-table-column prop="interfaceCount" label="接口数">
				  <template slot-scope="scope">
						<span v-if="scope.row.type==10">所有</span>
						<span v-else>{{scope.row.interfaceCount}}</span>
				  </template>
				</el-table-column>
			  <el-table-column prop="enable" label="启用">
			    <template slot-scope="scope">
			  	  <el-switch
			  	    v-model="scope.row.enable"
			  	    disabled>
			  	  </el-switch>
			    </template>
			  </el-table-column>
			  <el-table-column label="操作" width="380px">
				<template slot-scope="scope">
					<!--<operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.userId)"></operationBtn>!-->
					<operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row.userId)"></operationBtn>
					<operationBtn v-if="scope.row.type!=10" :btnName="'接口管理'"  @click.native="onInterface(scope.row.userId)"></operationBtn>
					<operationBtn :btnName="'删除'" v-if="currentUserType==10 && scope.row.userId != currentUserId" @click.native="onDelete(scope.row.userId)"></operationBtn>
					<operationBtn :btnName="'初始化密码'"  @click.native="onResetPwd(scope.row.userId)"></operationBtn>
	
				</template>
			 </el-table-column>
	    </el-table>
	  </div>
		<div style="height: 60px;padding-top:20px;">
		  <comPage
		    :paging="page"
		    :pageBtnList="pageBtnList"
		    @pagingClick="pagingClick"
		    @pageBtnClick="pageBtnClick">
		  </comPage>
		</div>
	</el-card>
  </div>
</template>

<script>
  import comPage from '@/components/page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default{
    components:{
      comPage,operationBtn,search
    },
    data(){
      return{
				currentUserId:null,
				currentUserType:1,
				fullscreenLoading:false,
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				paging: { //分页数据
					pageLength: 0, // 总共页数
					Size: 10, // 当前请求数量
					Index: 1 //当前在第几页
				},
				isPower: {
						
				},
				SearchList: null,//搜索集合
      };
    },
	created()
	{
		let that = this;
		/*isPower(that, "sysuser_details","sysuser_details");
		isPower(that, "sysuser_create","sysuser_create");
		isPower(that, "sysuser_edit","sysuser_edit");
		isPower(that, "sysuser_delete","sysuser_delete");
    isPower(that, "sysuser_range","sysuser_range");
    isPower(that, "sysuser_initPassword","sysuser_initPassword");*/
		that.currentUserType = JSON.parse(localStorage.getItem("currentUser")).userType;
		that.currentUserId = JSON.parse(localStorage.getItem("currentUser")).userId;
		pagingPage(that);
	},
	methods:{
		onSearch(params)
		{
			let that = this;
			let data = {};
			let searchData = params;
			searchData.forEach(element => {
				if (element.data) {
					data[element.zhi] = element.data;
				}
			});
			data.PageNumer = 1;
			routerPath(that, "/Admin/user/index", data, null, "PageSize");
		},
		onAdd()//新增
		{
			let that = this;
			// that.$router.push('/Admin/user/Create');
      that.$router.push({
      	path: "/Admin/user/Create/",
      	query: {}
      });
		},
		onDetail(Id)//查看
		{
			let that = this;
			that.$router.push({
				path: "/Admin/user/Detail/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});


		},
		onInterface(Id)
		{
			let that = this;
			that.$router.push({
				path: "/Admin/user/Interface/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onEdit(Id)//编辑
		{
			let that = this;
			that.$router.push({
				path: "/Admin/user/Edit/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onDelete(Id) //删除
		{
			let that = this;
			confirmDelete(that,null,function(res){
				if(res == true)
				{
					that.fullscreenLoading = true;
					ajaxDelete(that,"/api/admin/sysUser/Remove/" + Id,null,function(r){
						that.fullscreenLoading = false;
						pagingPage(that);
					});
				}
			})
		},
    onResetPwd(Id)//初始化密码
    {
      let that = this;
      confirmResetPwd(that,null,function(res){
      	if(res == true)
      	{
      		that.fullscreenLoading = true;
      		ajaxDelete(that,"/api/admin/sysuser/resetpassword/" + Id,null,function(r){
      			that.fullscreenLoading = false;
      			pagingPage(that);
      		});
      	}
      })
    },
		pageBtnClick(index) {
			//分页组件左侧的按钮事件，按顺序进行判断
		},
		pagingClick(type, val) {
			let that = this;
			let url = "/Admin/user/index";
			if (type == 1) {//更改每页条数触发的事件
				routerPath(
					that,
					url,
					"PageSize",
					val,
					"PageNumer",
					"Name",

				);
			} else { //更改当前页时触发的事件
				routerPath(
					that,
					url,
					"PageNumer",
					val,
					"PageSize",
					"Name",
				);
			}
		},
	},
	watch: {
		$route() {
			let that = this;
			pagingPage(that);
		}
	}
  };

 function pagingPage(that) {
	  paging(
		  that,
		  function (r) {
			  let Myurl = "/api/admin/sysUser/GetPage";
			  let data = {
				  PageNumer: r.PageNumer,
				  PageSize: r.PageSize,
				  Name: r.Name,
			  };

			  that.SearchList = [
				  {
					  type: "input",
					  Name: "用户",
					  data: r.Name,
					  holder: "请输入用户名/手机/姓名",
					  zhi: "Name"
				  },
			  ];
			  that.fullscreenLoading = true;
			  ajaxGet(that, Myurl, data, function (r) {
         console.log(r);
				  that.page = r;
				  that.item = r.items;

					//JSON.parse(that.$GetKey("userName"))
					for(var it of that.item)
					{
						it.isDelete = true;
						if(it.userName.toLowerCase() == JSON.parse(that.$GetKey("currentUser")).userName.toLowerCase())
						{
							it.isDelete = false;
						}
					}
				  that.fullscreenLoading = false;
			  });
		  },
		  "Name",
	  );
  }

// 初始化密码确认弹出框
  function confirmResetPwd(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要初始化密码吗？ 初始化后的密码是fenfa12345678";
    }
    confirm(that, msg, callback);
  }
</script>

<style scoped="scoped">

</style>
